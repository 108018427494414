import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 314px;
  height: 177px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #171717;
    font-size: 18px;
    font-weight: 500;
  }
  .member-icon {
    position: absolute;
    width: 112px;
    height: 46px;
    right: 8px;
    top: 8px;
  }
  .place-holder-icon {
    position: absolute;
    width: 160px;
    height: 100px;
    right: 8px;
    top: 8px;
  }
  .place-holder-text-container {
    position: absolute;
    top: calc(50% - 54px);
    left: 24px;
    .text {
      font-size: 36px;
      font-weight: 700;
      color: #121212;
    }
    &.member {
      .text {
        color: #ffffff;
      }
    }
  }
`
