import React from 'react'
import PropTypes from 'prop-types'

import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import ProgressiveImage from '../ProgressiveImage/dynamic'

import DetectionSnapshotStyled from './styled'
import MemberIcon from '../../assets/images/detection-snapshot/member-icon.svg'
import MemberPlaceHolderImg from '../../assets/images/detection-snapshot/placeholder-member.jpg'
import NonMemberPlaceHolderImg from '../../assets/images/detection-snapshot/placeholder-non-member.jpg'
import PlaceHolderIconImg from '../../assets/images/detection-snapshot/placeholder-icon.svg'

import { withTranslation } from '../../../i18n'

export class DetectionSnapshot extends React.PureComponent {
  getImage() {
    let output = null
    const isNoData = !this.props.detectionData
    const isNoImgSrcAndMember = !this.props.imgSrc && this.props.isMember
    const isNoImgSrcAndNonMember = !this.props.imgSrc && !this.props.isMember
    if (this.props.imgSrc) {
      output = <ProgressiveImage id={`${this.props.id}-progressive-img`} placeHolderImgSrc={this.props.placeHolderImgSrc} src={this.props.imgSrc} />
    } else if (isNoData) {
      output = <div className="no-image">{this.props.t('no_data')}</div>
    } else if (isNoImgSrcAndMember) {
      output = <ProgressiveImage id={`${this.props.id}-progressive-img`} placeHolderImgSrc={MemberPlaceHolderImg} src={MemberPlaceHolderImg} />
    } else if (isNoImgSrcAndNonMember) {
      output = <ProgressiveImage id={`${this.props.id}-progressive-img`} placeHolderImgSrc={NonMemberPlaceHolderImg} src={NonMemberPlaceHolderImg} />
    }
    return output
  }

  getPlaceHolderText() {
    let output = null
    if (!this.props.imgSrc && this.props.detectionData) {
      let className = 'place-holder-text-container'
      className += this.props.isMember ? ' member' : ''
      output = (
        <div className={className}>
          <div className="text">{this.props.isMember ? 'รถสมาชิก' : 'รถผู้มาติดต่อ'}</div>
          <div className="text">{this.props.isMember ? 'Member' : 'Non-member'}</div>
        </div>
      )
    }
    return output
  }

  getIcon() {
    let output = null
    const memberIcon = <img id="img-member-icon" className="member-icon" src={MemberIcon} />
    const placeholderIcon = <img id="place-holder-icon" className="place-holder-icon" src={PlaceHolderIconImg} />
    if (!this.props.imgSrc && this.props.detectionData) {
      output = placeholderIcon
    } else if (this.props.imgSrc && this.props.isMember) {
      output = memberIcon
    }
    return output
  }

  getContent() {
    return (
      <>
        {this.getImage()}
        {this.getIcon()}
        {this.getPlaceHolderText()}
      </>
    )
  }

  getLoading() {
    return <SkeletonAnimation id="detection-snapshot-loading" />
  }

  render() {
    return (
      <DetectionSnapshotStyled id={this.props.id} data-test={this.props.id} className={this.props.className}>
        {this.props.loading ? this.getLoading() : this.getContent()}
      </DetectionSnapshotStyled>
    )
  }
}

DetectionSnapshot.defaultProps = {
  id: 'detection-snapshot',
  isMember: false,
  loading: false,
  imgSrc: '',
  placeHolderImgSrc: '',
}

DetectionSnapshot.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  placeHolderImgSrc: PropTypes.string,
  isMember: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  detectionData: PropTypes.object,
}

export default withTranslation()(DetectionSnapshot)
